.header {
    margin: 0;
    padding: 0px;
    background-color: aquamarine;
    text-align: center;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.header > h1 {
    margin-left: auto;
    margin-right: auto;
    font-size: 30px;
}

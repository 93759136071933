.map-wrap {
    position: relative;
    width: 100%;
    height: calc(100vh - 60px);
}

.map {
    position: absolute;
    width: 100%;
    height: 100%;
}

.dropdown {
    z-index: 99999;
    position: absolute;
    background-color: white;
    display: flex;
    flex-direction: column;
}
